<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Quotes')" title-actions>
      <template #titleActions>
        <div class="d-flex flex-column flex-md-row align-end align-md-center justify-end">
          <v-text-field
            v-model="search"
            class="mb-4 mb-md-0 mr-md-4"
            outlined
            hide-details="auto"
            dense
            type="text"
            label="Search Quote"
            style="max-width: 200px"
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn to="Quotes/create" color="secondary" rounded dark>
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-sm-inline">Quote erstellen</span>
          </v-btn>
        </div>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="quotes"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (item.status === 'expired' ? highlightClasses : '')"
      >
        <template #[`item.client`]="{ item }">
          <router-link v-if="item.client_id" :to="{ name: 'clients-item', params: { id: item.client_id } }">
            <b>{{ item.client.contact_handle.organisation }}</b>
            {{ item.client.contact_handle.firstname }}
            {{ item.client.contact_handle.lastname }}
          </router-link>
        </template>

        <template #[`item.quotedate`]="{ item }">
          {{ item.quotedate | moment('DD.MM.YYYY') }}
        </template>

        <template #[`item.amount`]="{ item }">{{ $n(item.amount, 'currency', 'de-DE') }}</template>

        <template #[`item.status`]="{ item }">
          <QuoteRowStateButtons
            :quote="item"
            :pending-quotes="pendingQuotes"
            :disabled="pending"
            @onChangeState="changeState(item.id, ...arguments)"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <QuoteRowActions
            :quote="item"
            :pending-quotes="pendingQuotes"
            @onRemove="remove"
            @onDownload="download(item)"
          />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, computed, watch, ref, reactive } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import QuoteRowActions from '@/components/Quotes/QuoteRowActions.vue'
import QuoteRowStateButtons from '@/components/Quotes/QuoteRowStateButtons.vue'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewQuotes',
  components: {
    AppCard,
    AppPagination,
    QuoteRowActions,
    QuoteRowStateButtons,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $vuetify, $router }, root }) {
    const page = ref(1)
    const search = ref(null)
    const pendingQuotes = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Quote Nr.',
        value: 'quote_nr',
      },
      {
        sortable: false,
        text: 'Quotedate',
        value: 'quotedate',
      },
      {
        sortable: false,
        text: 'Amount',
        value: 'amount',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const quotes = computed(_ => $store.state.quotes.quotes)
    const pagination = computed(_ => $store.state.quotes.pagination)
    const pending = computed(_ => $store.state.quotes.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getQuotes = payload => $store.dispatch('quotes/getQuotes', payload)
    const setStatus = status => $store.dispatch('quotes/setStatus', status)
    const deleteQuote = quoteId => $store.dispatch('quotes/deleteQuote', quoteId)
    const getQuotePdf = quoteId => $store.dispatch('quote/getQuotePdf', quoteId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getQuotes({ page: page.value })

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      clearTimeout(delayTimer)
      getQuotesWithParams()
    })

    const delayTimer = ref(null)
    watch(search, _ => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        page.value = 1
        getQuotesWithParams()
      }, 500)
    })

    const getQuotesWithParams = _ => {
      const params = {
        page: page.value,
      }

      if (search.value) {
        params.search = search.value
      }

      getQuotes(params)
    }

    const changeState = (id, status) => {
      const quote = {
        id,
        status,
      }

      const pendingButtonIndex = pendingQuotes.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingQuotes.push(id)

      setStatus(quote)
        .then(response => {
          pendingQuotes.splice(pendingButtonIndex, 1)

          addToast({
            msg: 'Status erfolgreich geändert',
            type: 'info',
          })

          getQuotesWithParams()
        })
        .catch(error => {
          addToast({
            msg: 'Statusänderung fehlgeschlagen',
            type: 'error',
          })
          pendingQuotes.splice(pendingButtonIndex, 1)
        })
    }

    const remove = id => {
      const pendingButtonIndex = pendingQuotes.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      if (confirm('Sicher, dass die Rechnung gelöscht werden soll?')) {
        pendingQuotes.push(id)

        deleteQuote(id)
          .then(response => {
            pendingQuotes.splice(pendingButtonIndex, 1)

            addToast({
              msg: 'Angebot erfolgreich gelöscht',
              type: 'success',
            })

            if (pagination.value.current === 1 && page.value !== 1) {
              $router.push({ query: { page: page.value - 1 } })
            } else {
              getQuotesWithParams()
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
            pendingQuotes.splice(pendingButtonIndex, 1)
          })
      }
    }

    const download = quote => {
      const pendingButtonIndex = pendingQuotes.indexOf(quote.id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingQuotes.push(quote.id)

      getQuotePdf(quote.id)
        .then(quotePdf => {
          pendingQuotes.splice(pendingButtonIndex, 1)
          const link = document.createElement('a')
          link.download = `${quote.quote_nr || 'angebot_entwurf'}.pdf`
          link.href = 'data:application/octet-stream;base64,' + quotePdf
          link.click()
        })
        .catch(error => {
          addToast({
            msg: 'Download fehlgeschlagen',
            type: 'error',
          })
          pendingQuotes.splice(pendingButtonIndex, 1)
        })
    }

    return {
      headers,
      search,
      quotes,
      pagination,
      pendingQuotes,
      pending,
      highlightClasses,
      remove,
      changeState,
      download,
    }
  },
}) //
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
