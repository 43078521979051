<template>
  <OverviewActions
    type="quotes"
    :item-id="quote.id"
    info
    download
    :edit="quote.status === 'draft'"
    :remove="quote.status === 'draft'"
    :disable-remove="isQuotePending"
    :disable-download="isQuotePending"
    @onRemove="removeQuote"
    @onDownload="downloadQuote"
  />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'QuoteRowActions',
  components: {
    OverviewActions,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    pendingQuotes: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit }) {
    const isQuotePending = computed(_ => props.pendingQuotes.indexOf(props.quote.id) !== -1)

    const removeQuote = id => {
      emit('onRemove', id)
    }

    const downloadQuote = id => {
      emit('onDownload', id)
    }

    return {
      isQuotePending,
      removeQuote,
      downloadQuote,
    }
  },
}) //
</script>

