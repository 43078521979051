<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on }">
        <v-btn
          small
          :disabled="disabledButtons"
          :class="{
            warning: status === 'draft',
            info: status === 'open',
            error: status === 'expired',
            success: status === 'accepted',
          }"
          class="state-button"
          v-on="on"
        >
          {{ status }}
          <v-icon right dark>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="status === 'draft'" dense @click="changeState('open')">
          <v-list-item-title>open</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="status === 'open' && status !== 'expired'" dense @click="changeState('accepted')">
          <v-list-item-title>accepted</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="status === 'open' || status === 'accepted'" dense @click="changeState('invoice')">
          <v-list-item-title>to invoice</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="status === 'open' || status === 'accepted'" dense @click="changeState('expired')">
          <v-list-item-title>expired</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="status === 'expired'" dense @click="changeState('renew')">
          <v-list-item-title>renew</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'QuoteStateButtons',
  props: {
    status: {
      type: String,
      required: true,
    },
    disabledButtons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const changeState = state => {
      emit('onChangeState', state)
    }

    return {
      changeState,
    }
  },
})
</script>

<style lang="scss" scoped>
.btn-group.state > .btn {
  margin-top: 10px;
  margin-bottom: 0;
}
</style>
