<template>
  <QuoteStateButtons
    v-if="quote.status !== 'invoice' && quote.status !== 'renew'"
    :status="quote.status"
    :disabled-buttons="isQuotePending || disabled"
    @onChangeState="changeState"
  />
  <v-chip v-else-if="quote.status === 'invoice'" color="success" label>
    {{ quote.status }}
  </v-chip>
  <v-chip v-else-if="quote.status === 'renew'" color="info" label>
    {{ quote.status }}
  </v-chip>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

import QuoteStateButtons from '@/components/Quotes/QuoteStateButtons'

export default defineComponent({
  name: 'QuoteRowStateButtons',
  components: {
    QuoteStateButtons,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    pendingQuotes: {
      type: Array,
      default: _ => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isQuotePending = computed(_ => props.pendingQuotes.indexOf(props.quote.id) !== -1)

    const changeState = state => {
      emit('onChangeState', state)
    }

    return {
      isQuotePending,
      changeState,
    }
  },
})
</script>

<style lang="scss" scoped>
table .label {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn.btn-primary {
  margin: 0;
}
// .btn-group.state > .btn {
//   margin-top: 10px;
//   margin-bottom: 0;
// }
</style>
