var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"state-button",class:{
          warning: _vm.status === 'draft',
          info: _vm.status === 'open',
          error: _vm.status === 'expired',
          success: _vm.status === 'accepted',
        },attrs:{"small":"","disabled":_vm.disabledButtons}},on),[_vm._v(" "+_vm._s(_vm.status)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[(_vm.status === 'draft')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeState('open')}}},[_c('v-list-item-title',[_vm._v("open")])],1):_vm._e(),(_vm.status === 'open' && _vm.status !== 'expired')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeState('accepted')}}},[_c('v-list-item-title',[_vm._v("accepted")])],1):_vm._e(),(_vm.status === 'open' || _vm.status === 'accepted')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeState('invoice')}}},[_c('v-list-item-title',[_vm._v("to invoice")])],1):_vm._e(),(_vm.status === 'open' || _vm.status === 'accepted')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeState('expired')}}},[_c('v-list-item-title',[_vm._v("expired")])],1):_vm._e(),(_vm.status === 'expired')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.changeState('renew')}}},[_c('v-list-item-title',[_vm._v("renew")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }